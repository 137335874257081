<template>
  <div class="mb-3 pb-5">
    <CCard>
      <CCardBody>
        <div class="d-flex pb-3 desktop-view">
          <span>
            <span class="text-primary desktop-view h6">
              <!-- Job {{ activityDetails.job_id }} : -->
              Job {{ activityDetails.job_display_uid }} :
              {{ activityDetails.job.job_title }}
            </span>
            <span class="text-primary d-md-block d-none desktop-view h6">
              <!-- Candidate {{ activityDetails.candidate_uid }} : -->
              Candidate {{ activityDetails.candidate_display_uid }} :
              {{ activityDetails.candidate.full_name }}
            </span>
          </span>
          <div class="d-flex ml-auto mr-0">
            <span class="px-2 text-primary desktop-view h6">
              <span
                >Completed on:
                {{ activityDetails.initiated_date | formatDate }}</span
              >
            </span>
          </div>
        </div>

        <div class="pb-3 mobile-view">
          <span>
            <span class="text-primary mobile-view h6">
              <!-- Job {{ activityDetails.job_id }} : -->
              Job {{ activityDetails.job_display_uid }} :
              {{ activityDetails.job.job_title }}
            </span>
            <span class="text-primary mobile-view h6 d-block">
              <!-- Candidate {{ activityDetails.candidate_uid }} : -->
              Candidate {{ activityDetails.candidate_display_uid }} :
              {{ activityDetails.candidate.full_name }}
            </span>
          </span>
          <div class="d-flex ml-auto mr-0">
            <span class="text-primary font-weight-bold mobile-view">
              <span
                >Completed on:
                {{ activityDetails.initiated_date | formatDate }}</span
              >
            </span>
          </div>
        </div>
        <div>
          <div class="pt-2">
            <CRow>
              <CCol md="7">
                <CDataTable
                  :striped="true"
                  :bordered="true"
                  :small="true"
                  :items="activityComments"
                  :fields="fields"
                  pagination
                  :items-per-page="5"
                  class="p-2"
                >
                </CDataTable>
              </CCol>
              <CCol md="5">
                <div class="pb-2">
                  <CTabs
                    variant="tabs"
                    :active-tab="0"
                    @update:activeTab="changeActiveTab"
                  >
                    <CTab title="Candidate">
                      <div class="mt-1 mb-2">
                        <CButton
                          type="button"
                          color="primary"
                          class="mx-1 py-1 mt-1"
                          @click="openDocument(activityDetails.candidate_uid)"
                          >View Documents</CButton
                        >
                        <CButton
                          type="button"
                          color="primary"
                          class="mx-1 py-1 mt-1"
                          @click="
                            navToCandidateDetails(activityDetails.candidate_uid)
                          "
                          >View Profile</CButton
                        >
                        <CButton
                          type="button"
                          color="primary"
                          class="mx-1 py-1 mt-1"
                          @click="openFrontSheet"
                        >
                          Frontsheet
                          <i class="fas fa-download ml-2"></i>
                        </CButton>
                      </div>
                    </CTab>
                    <CTab title="Job">
                      <div class="mt-1 mb-2">
                        <CButton
                          type="button"
                          color="primary"
                          class="mx-1 py-1 mt-1"
                          @click="openJobAdvert(activityDetails.job_id)"
                          >Preview Advert</CButton
                        >
                        <CButton
                          type="button"
                          color="primary"
                          class="mx-1 py-1 mt-1"
                          v-if="!isHeadlessClient"
                          @click="navToJobDetails(activityDetails.job_id)"
                          >Job Details</CButton
                        >
                      </div>
                    </CTab>
                  </CTabs>
                </div>
                <div
                  v-if="showDocuments && activeTab === 0"
                  class="
                    d-flex
                    align-items-center
                    documentScrolling
                    document-table
                    border
                    mb-2
                  "
                >
                  <table class="w-100 table">
                    <thead>
                      <th>Type</th>
                      <th>Name</th>
                      <th>Download</th>
                      <th>Preview</th>
                    </thead>
                    <tbody>
                      <tr v-for="(document, index) in documents" :key="index">
                        <td>{{ document.lable }}</td>
                        <td>{{ document.name }}</td>
                        <td>
                          <CButton
                            type="button"
                            @click="fileDownload(document)"
                          >
                            <i class="fas fa-download"></i>
                          </CButton>
                        </td>
                        <td>
                          <CButton type="button" @click="preview(document)">
                            <i class="fas fa-eye"></i>
                          </CButton>
                        </td>
                      </tr>
                      <tr v-if="documents.length === 0">
                        <td colspan="4">
                          <h6 class="text-center text-muted">
                            No Documents to Preview
                          </h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CCol>
            </CRow>
          </div>
        </div>
      </CCardBody>
    </CCard>
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
    <JobCardPreviewModal
      :isShowPopup="advertModal.isShowPopup"
      :onCancelPreview="advertModalCallBack"
    />
    <FrontSheetModal
      v-if="frontSheetModal.isShowPopup"
      :isShowPopup="frontSheetModal.isShowPopup"
      @modalCallBack="frontSheetModalCallBack"
      :candidateDetail="frontSheetModal.candidate"
      :jobDetail="frontSheetModal.job"
      :frontSheetInfo="frontSheetModal.frontSheetInfo"
      :frontSheets="frontSheetModal.comments"
    />
  </div>
</template>
<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import pdf from "vue-pdf";
import PreviewModal from "@/components/reusable/PreviewModal";
import { BASE_URL } from "@/service_urls";
import JobCardPreviewModal from "@/components/jobCardPreviewModal";
import FrontSheetModal from "@/containers/Frontsheet/FrontSheetModal";
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    activityDetails: {
      type: Object,
      default: () => null,
    },
    comments: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    PreviewModal,
    FrontSheetModal,
    JobCardPreviewModal,
  },
  data() {
    return {
      activeTab: 0,
      advertModal: {
        isShowPopup: false,
      },
      showDocuments: false,
      review: {},
      payload: {},
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      fields: [
        { key: "date", _style: "width:25%" },
        {
          key: "name",
          _style: "width:25%",
        },
        {
          key: "role",
          _style: "width:10%",
        },
        {
          key: "status",
          _style: "width:15%",
        },
        {
          key: "comment",
          _style: "width:25%",
        },
      ],
      frontSheetModal: {
        isShowPopup: false,
        candidate: null,
        job: null,
        frontSheetInfo: null,
        comments: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      "getUploadedDocument",
      "getCustomerId",
      "isHeadlessClient",
      "candidateProfile",
    ]),
    activityComments() {
      return (
        this.comments
          // .filter((e) => e.comments)
          .filter(
            (e) =>
              [54, 52].includes(e.status_id) &&
              [53, 44, 50, 47].includes(e.sub_status_id)
          )
          .map((v) => ({
            name: v.initiated_by_user_name || "--",
            date: this.formatDate(v.created_on) || "--",
            comment: v?.comments || "--",
            role:
              (v?.initiated_by_user?.roles &&
                v?.initiated_by_user?.roles
                  .map((v) => _.startCase(v.role_type))
                  .join()) ||
              "--",
            status:
              `${v?.status} ${v?.sub_status ? " - " + v?.sub_status : ""}` ||
              "--",
          })) || []
      );
    },
    documents() {
      return (
        (this.getUploadedDocument &&
          this.getUploadedDocument.length &&
          this.getUploadedDocument
            .filter(({ document_type_id }) =>
              this.isHeadlessClient ? document_type_id === 20 : true
            )
            .map(
              ({
                document_type_id,
                link_to_doc,
                document_name,
                document_type,
                created_on,
                document_ext,
                candidate_document_id,
                comments,
              }) => {
                return {
                  link: link_to_doc,
                  lable: document_type.comments,
                  name: document_name,
                  updatedDate: m(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext?.document_ext,
                  document_id: candidate_document_id,
                  comments: comments,
                  document_type_id: document_type_id,
                };
              }
            )) ||
        []
      );
    },
  },
  methods: {
    ...mapActions([
      "setCandidateListBackUrl",
      "fetchDocumentByCandidateID",
      "showToast",
      "fetchActivity",
      "downloadDocument",
      "fetchJobBenefitsAdvertDescByJobId",
      "fetchJobCandidateFrontSheet",
      "addJobCandidateFrontSheet",
      "getCandidateDetails",
    ]),
    changeActiveTab(tab) {
      this.activeTab = tab;
    },
    openJobAdvert(job_id) {
      this.fetchJobBenefitsAdvertDescByJobId({
        job_id,
        customer_id: this.getCustomerId,
      }).then((res) => {
        this.advertModal.isShowPopup = true;
      });
    },
    advertModalCallBack() {
      this.advertModal.isShowPopup = false;
    },
    openDocument(activityDetails) {
      this.showDocuments = !this.showDocuments;
    },
    navToCandidateDetails(candidate_uid) {
      // this.setCandidateListBackUrl(this.$route.fullPath);
      // this.$router.push({ path: `/candidate/${candidate_uid}` });
      // let routeData = this.$router.resolve({
      //   path: `/candidate/${candidate_uid}`,
      // });
      // window.open(routeData.href, "_blank");
      this.$emit("openCandidateInfoModal", candidate_uid);
    },
    navToJobDetails(job_id) {
      // let fullPath = this.$route.fullPath;
      // this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      // this.$router.push({ path: `/job-list-preview/${job_id}` });
      this.$emit("openJobInfoModal", job_id);
    },
    async onSubmit(action) {
      const isValid = await this.$refs.reviewCandidate.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let finalPayload = {
        job_id: this.activityDetails?.job_id,
        candidate_uid: this.activityDetails?.candidate_uid,
        comments: this.review?.comments,
        action_detail_id: this.activityDetails?.action_detail_id,
      };
      this.$emit("changeActivityStatus", { action, payload: finalPayload });
    },
    fileDownload(data) {
      let { link, name: document_name } = data;
      let url = link.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name });
    },
    preview(data) {
      var _baseUrl = BASE_URL;
      // var _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
      var _base_domain = "https://" + window.location.host;
      if (["PNG", "JPG", "JPEG", "GIF"].includes(data?.document_ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = _base_domain + data.link;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data?.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(_base_domain + data.link);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        ["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(data.document_ext)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          _base_domain +
          data.link +
          "";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    navToActivityList() {
      this.$store.commit("SET_ACTIVE_TAB", 1);
      this.$router.push({ path: `/list-activity` });
    },
    formatDate(data) {
      if (data) return m(data).format("Do MMM YYYY");
      return "--";
    },
    openFrontSheet() {
      const { candidate, job } = this.activityDetails;
      const { job_id } = job;
      const { candidate_uid } = candidate;
      this.fetchJobCandidateFrontSheet({
        job_id,
        candidate_uid,
        order_by: "-job_candidate_front_sheet_id",
      }).then(async (res) => {
        if (res?.data) {
          await this.getCandidateDetails(candidate_uid);
          this.frontSheetModal.job = job;
          this.frontSheetModal.candidate = this.candidateProfile;
          this.frontSheetModal.frontSheetInfo = res?.data.length
            ? res?.data[0]
            : null;
          this.frontSheetModal.isShowPopup = true;
          this.frontSheetModal.comments = res?.data || [];
        } else {
          this.showToast({
            class: "bg-danger text-white",
            message: "Error while fetching Frontsheet",
          });
        }
      });
    },
    frontSheetModalCallBack(action, payload) {
      if (action && payload) {
        this.addJobCandidateFrontSheet(payload).then((res) => {
          this.frontSheetModal.isShowPopup = false;
        });
      } else {
        this.frontSheetModal.isShowPopup = false;
      }
    },
  },
  mounted() {
    const {
      candidate_uid,
      candidate: { customer_uid },
      action_detail_id,
    } = this.activityDetails;
    this.fetchDocumentByCandidateID({ candidate_uid, customer_uid });
  },
  filters: {
    formatDate(data) {
      if (data) return m(data).format("Do MMM YYYY");
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.action-btn {
  width: 110px;
}
.center {
  display: flex;
  justify-content: center;
}
.mobile-view {
  @media (min-width: 900px) {
    display: none;
  }
}
.desktop-view {
  @media (max-width: 900px) {
    display: none;
  }
}
</style>
