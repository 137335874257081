import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import m from "moment";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import DateInput from "@/components/reusable/Fields/DateInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import CheckBox from "@/components/reusable/Fields/CheckBox";
import RichTextEditor from "@/components/reusable/Fields/RichTextEditor";
import { CONSTANT as CONST } from "../../helpers/helper";

export default {
  components: {
    Select,
    TextInput,
    PhoneInput,
    TextareaInput,
    DatePicker,
    DateInput,
    RadioButton,
    CheckBox,
    RichTextEditor,
  },

  computed: {
    ...mapGetters([
      //Common
      "organizationId",
      "jobById",
      "getCurrencyForSelectedID",
      "getEnumLabels",
      "getIsParentUser",
      "getUserId",

      //JobInfo
      "candidateType",
      "getLocationCountryInfo",
      "getNationalityCountryInfo",
      "candidateLevelByTypeAndCountry",
      "specialityByTypeAndCountry",
      "getOrganisationRegions",
      "subSpecialityFilterBySpeciality",
      "getNurseSubSpeciality",
      "getQualificationCountries",
      "getQualificationCountryByRegion",
      "getRegionNameForSelectedId",
      "getCountryNameForSelectedId",
      "getLevelForSelectedId",
      "locationLanguage",
      "getLanguageForSelectedId",
      "specialInterestFilterBySpeciality",
      "getContactUserByTypeForJob",
      "getLocationByOrganisation",
      "specialInterestFilterAHP",
      "getOrgContacts",
      "getOrgContactForSelectedId",
      "getJobContactRecruiters",
      "getCandidateBands",
      "getQualificationClassification",

      //Benefits
      "getOrgJobBenefits",

      //JobAdditionalInfo
      "getOrgJobDetails",
      "getAdLocationForSelectedID",
      "getFieldsInAdvert",
      "getSectionDisplayNames",
      "getSectionByDisplayNames",

      // Organisation documents
      "getAllOrganisationDocumentByType",
      // get filtered Special Interest by specialty ID
      "getFilteredSpecialInterest",

      // for pertemps
      "agencyAndPartnerHospitals",
      "childHasNoAcronym",
      "isUKMainAgency",
      "getOrgIDFromAccessToken",
      "actionComments",
      "isJobSupplierFromAccessToken",

      // action documents
      "jobDocumentActions",
    ]),
  },
  methods: {
    ...mapActions([
      // General
      "showLoader",
      "hideLoader",
      "showToast",

      // Job Related
      "fetchJobByID",
      "fetchNurseSubSpeciality",
      "addJobs",
      "updateJobSteps",
      "updateJobSpeciality",
      "fetchOrganisationRegions",
      "fetchQualificationCountriesByRegion",
      "createJobCountry",
      "deleteJobCountryById",
      "fetchFieldsInAdvert",
      "fetchOrgJobDetails",
      "createAdditionalInfo",
      "updateAdditionalInfo",
      "deleteAdditionalInfo",
      "fetchContactUserForSelectedJob",
      "fetchSpecialInterestByParams",
      "fetchEnumLabels",
      "fetchOrgContacts",
      "fetchCandidateBands",
      "fetchQualificationClassification",
      "fetchCommentsfor_jobapproval",

      // Benefits
      "deleteBenefitWithoutToast",
      "fetchOrgJobBenefits",

      // Template
      "fetchSectionDisplayNames",
      "fetchSectionByDisplayNames",
      "createJobDescSections",
      "deleteJobDescSectionByID",

      // Job Picture
      "fetchAllVersionDocumentByOrgId",

      // Document library
      "fetchCustomerDocumentLib",
      "getJobDocumentAction"
    ]),
    handleInput(name, value) {
      Vue.set(this.jobs, name, value);
      Vue.set(this.payload, name, value);
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.jobs, name, value);
      Vue.set(this.payload, name, value ? m(value).format("YYYY-MM-DD") : null);
    },
    changeFieldValues(name, value) {
      Vue.set(this.jobs, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeSelectCustom(name, value) {
      const comment = "publish_request_comments";
      const v = value ? value.id || value.code || value.label || value : null;
      Vue.set(this.jobs, comment, v);
      Vue.set(this.jobs, name, v);
      this.payload = {
        ...this.payload,
        [comment]: v,
      };
    },
    handleChangeSelect(name, value) {
      if (
        (name === "speciality" &&
          this.jobs?.other_areas_of_expertise_ids?.some(({ speciality_id, sub_speciality_id }) => {
            if (!value.sub_speciality_id)
              return speciality_id === value.speciality_id;
            else
              return speciality_id === value.speciality_id && sub_speciality_id === value.sub_speciality_id;
          })) ||
        (name === "other_areas_of_expertise_ids" &&
          value?.some(({ speciality_id, sub_speciality_id }) => {
            if (!this.jobs?.sub_speciality?.code)
              return speciality_id === this.jobs?.speciality?.code;
            else
              return speciality_id === this.jobs?.speciality?.code && sub_speciality_id === this.jobs?.sub_speciality?.code;
          }))
      ) {
        this.showErrorToast("Speciality already exists! Please select another Speciality");
        return;
      }
      if (
        (name === "speciality" && this.jobs?.candidateType?.code == CONST.NON_CLINIC_ADMIN &&
          this.jobs?.other_areas_of_expertise_ids?.some(({ code }) => code == value.code)) ||
        (name === "other_areas_of_expertise_ids" && this.jobs?.candidateType?.code == CONST.NON_CLINIC_ADMIN &&
          value?.some(({ code }) => code == this.jobs?.speciality?.code))
      ) {
        this.showErrorToast("Speciality already exists! Please select another Speciality");
        return;
      }
      Vue.set(this.jobs, name, value);
      let code = value ? value.id || value.code || value : null;
      if (name == "benefit") return;
      this.payload = {
        ...this.payload,
        [name]: code,
      };
      if (name === "organisation") {
        this.$emit("showHintOrgName", "");
        // Checking current user and creating job organisation
        if (this.getIsParentUser && this.isChildOrg) {
          if (this.isUKMainAgency) {
            this.$emit("showHintOrgName", value?.label);
          }
          else {
            this.confirmationModal.isShowPopup = true;
          }
        }
        this.jobs.contact = value?.organisation?.main_contact?.name;
        this.fetchOptions(name, {
          customer_uid: value?.customer_uid,
          organisation_uid: value?.organisation?.organisation_id,
        });
        const contactFields = [
          "job_contact_recruiters",
          "job_approval",
          "candidate_interviewer",
          "candidate_reviewer"
        ];
        _.forEach(contactFields, (field) => {
          this.changeFieldValues(field, null);
        });
      }
      if (name == "candidateType") {
        this.$store.commit("SET_FILTERED_SPECIAL_INTEREST", []);
        this.jobs.other_areas_of_expertise_ids = [];
        this.jobs.experience_in_years = null;
        if ([CONST.PHYSICIAN, CONST.NON_CLINIC_ADMIN].includes(code)) {
          this.fetchOptions("qualification_classification", {
            candidate_type_id: code,
          })
        }
        if ([CONST.NURSE].includes(code)) {
          this.fetchOptions(name, {
            candidate_type_id: code,
          });
          this.fetchOptions('fetchSpecialityNurse');
        }
      }
      if (name === "speciality") {
        if (![CONST.NURSE, CONST.AHP, CONST.NON_CLINIC_ADMIN].includes(this.jobs?.candidateType?.code)) {
          this.fetchOptions(name, {
            speciality_id: value?.code,
            candidate_type_id: this.jobs?.candidateType?.code,
          });
        }
        if (this.jobs?.candidateType?.code == CONST.NURSE) {
          this.handleChangeSelect("sub_speciality", {
            code: value?.sub_speciality_id,
            label: value?.sub_speciality,
          });
        }
        else {
          this.handleChangeSelect("sub_speciality", {});
        }
      }
      if (name === "location_id" && value) {
        const { currency_code: label, currency_id: code } = value
        this.handleChangeSelect("allocated_budget_currency_id", { code, label })
        if (value.country_id == 182) {
          this.fetchOptions(name);
        }
      }

    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.jobs, name, val);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
    },
    handleChangeCheck(name, $event) {
      const checked = $event.target.checked;
      Vue.set(this.jobs, name, checked);
      this.payload = {
        ...this.payload,
        [name]: checked,
      };
    },
    handleMultiSelectArrayForCountries(name, value) {
      let last_index = value[value.length - 1];
      let val = null;
      if (last_index?.label == "All" || !last_index) {
        Vue.set(this.jobs, name, [{ code: 0, label: "All" }]);
        this.payload = {
          ...this.payload,
          [name]: val,
        };
      } else {
        if (value[0].label == "All") value = value.slice(1);
        val = value.map((v) => {
          return v.id || v.code || value;
        });
        Vue.set(this.jobs, name, value);
        this.payload = {
          ...this.payload,
          [name]: val,
        };
        if (name === "countries_included") {
          this.payload = {
            ...this.payload,
            [name]: value,
          };
        }
      }
      this.fetchOptions(name, val);
    },
    fetchOptions(name, val) {
      switch (name) {
        case "qualificationRegion":
          this.fetchQualificationCountriesByRegion(val);
          break;
        case "organisation":
          this.fetchOrgContacts({ customer_id: val?.customer_uid, organisation_id: val?.organisation_uid })
          this.fetchContactUserForSelectedJob(val);
          this.fetchOrganisationRegions(val.organisation_uid);
          this.fetchAllVersionDocumentByOrgId({ organisation_id: val.organisation_uid })
          break;
        case "candidateType":
          this.fetchSpecialInterestByParams(val);
          break;
        case "speciality":
          this.fetchSpecialInterestByParams(val);
          break;
        case "location_id":
          this.fetchCandidateBands();
          break;
        case "qualification_classification":
          this.fetchQualificationClassification(val);
          break;
        case "fetchSpecialityNurse":
          this.fetchNurseSubSpeciality();
          break;

        default:
          break;
      }
    },
    additionalRequirements(jobData) {
      let data = "";
      jobData?.preferred_in_advert?.forEach((val) => {
        switch (val) {
          case "special_interests":
            let special_interests =
              jobData?.specialities[0]?.job_special_interest?.map((v) => v.special_interest)?.join(", ");
            special_interests = jobData?.candidate_type_id != CONST.AHP
              ? special_interests
              : null;
            data = special_interests
              ? data +
              `<li>Special Interest in ${special_interests} preferred</li>`
              : data;
            break;
          case "minimum_years_of_experience":
            data = jobData.min_yrs_of_exp
              ? data +
              `<li>Minimum ${jobData.min_yrs_of_exp} Years of Experience preferred</li>`
              : data;
            break;
          case "languages":
            let languages =
              this.getLanguageForSelectedId(jobData.language_ids).map(
                (ele) => ele.label
              ) || false;
            languages = languages?.length ? languages.join(", ") : null;
            data = languages
              ? data + `<li>${languages} Language(s) preferred</li>`
              : data;
            break;
          case "local_license_or_eligibility":
            let license = jobData.regional_license;
            license =
              license != "n/a"
                ? license[0].toUpperCase() + license.substr(1)
                : "";
            data = license ? data + `<li>Local License ${license}</li>` : data;
            break;
          case "nationality":
            let nationalities =
              jobData.countries_included
                ?.map(({ country }) => country.nationality)
                .join(", ") || false;
            data = nationalities
              ? data + `<li>${nationalities} preferred</li>`
              : data;
            break;
          case "gender":
            data = jobData.gender?.gender
              ? data +
              `<li>${jobData.gender?.gender} preferred due to patient base</li>`
              : data;
            break;
          case "age":
            data = jobData.maximum_age
              ? data +
              `<li>Applicants under ${jobData.maximum_age} years preferred</li>`
              : data;
            break;

          default:
            return;
        }
      });
      return data ? `<ul>${data}</ul>` : "";
    },
    jobBenefits(benefits) {
      let stdBenefits = [];
      let addBenefits = [];
      benefits?.forEach((item) => {
        item?.organisation_job_benefit?.benefit_types == "standard_benefits" ? stdBenefits.push(item) : addBenefits.push(item)
      })
      stdBenefits = stdBenefits.sort((a, b) => a.organisation_job_benefit?.display_order - b.organisation_job_benefit?.display_order);
      addBenefits = addBenefits.sort((a, b) => a.organisation_job_benefit?.display_order - b.organisation_job_benefit?.display_order);
      benefits = stdBenefits.concat(addBenefits)
      benefits = benefits.map(val => ({
        benefit_type: val?.organisation_job_benefit?.benefit_type?.benefit_type,
        comments: val.comments,
        description: val.description,
      }));
      if (benefits?.length) {
        let list = ""
        benefits.forEach(item => {
          list += `<li>${item.description}${item.comments ? ` -${item.comments}` : ''}</li>`
        })
        return `<ul>${list}</ul>`
      }
      return false;
    },
    jobSectionAdditionalInfo(jobData) {
      let sections = jobData?.job_desc_section_templates;
      if (sections?.length) {
        sections = sections.filter((val) => !val.section_id)[0];
        return sections?.section_desc || "";
      }
      return "";
    },
    jobSections(jobData) {
      let sections = jobData?.job_desc_section_templates;
      if (sections?.length) {
        sections = sections.filter(val => val.section_id).map(val => {
          return {
            display_order: val?.section_template?.display_order,
            // Not Display Organisation description title in advert
            displayName: val?.section_template?.display_name == CONST.ORGANISATION_DESCRIPTION ? "" : val?.section_template?.display_name,
            content: val?.section_desc || val?.section_template?.section_desc
          }
        }).sort((a, b) => {
          return a.display_order - b.display_order;
        });
        let returnData = "";
        sections?.forEach(val => {
          let displayContent = val.content.replaceAll(
            "%salary_dynamic_text%",
            `${jobData?.salary_min_text || jobData?.salary_min} ${this.getCurrencyForSelectedID(jobData?.salary_currency_id)[0]
              ?.label
            } to ${jobData?.salary_max_text || jobData?.salary_max} ${this.getCurrencyForSelectedID(jobData?.salary_currency_id)[0]
              ?.label
            }`
          );
          let displayName = val.displayName ? `<h4 class="mt-3 mb-2"><strong>${val.displayName}:</strong></h4>` : "";
          returnData = returnData + displayName + displayContent
        })
        // Replace used to remove unwanted code between two tags and Replace All used to remove the margin bottom of <p> tag
        return returnData
          .replace(/\>\s+\</g, "><")
          .replaceAll("<p>", '<p class="mb-0">');
      }
      return "";
    },
    showErrorToast(message) {
      this.showToast({
        class: "bg-danger text-white",
        message: message,
      });
    },
  },
};
